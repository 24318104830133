import React, {useRef, useState} from "react";

export function VideoSelectorAddButtonFile({initContent, form, values ,...props }){
  const hiddenFileInput = useRef(null);

  const [isLoading, setLoading] = useState(false);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  function fetchValue(e) {
    setLoading(true)
    e.preventDefault();
    e.stopPropagation();
   
    const {target: {files}} = e;
    const file = files[0];
    console.log(file)
    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = (finishedEvent) => {
      const {currentTarget : { result }} = finishedEvent;
      const content = {...initContent, contentBody: file}
      form.setFieldValue(props.field.name, [...values, content])
    }
    setLoading(false);
  }
  return (
    <div>
      {isLoading && <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>}
      <input
        type="file"
        name="file"
        ref={hiddenFileInput}
        onChange={(event) => {
          console.log(event)
          fetchValue(event);
        }}
        style={{ display: 'none' }}
      />
      <button
        type="button"
        onClick={handleClick}
        className="btn btn-light"
      >
        <i className="fas fa-video"></i>
        <i className="fas fa-plus"></i>
      </button>
    </div>

  )
}