import React from "react";

export function FileSelectorFile({ value, form, content, contents, deletable ,...props }) {

  function fetchValue(e) {
    const {target: {files}} = e;
    const file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = (finishedEvent) => {
      const {currentTarget : { result }} = finishedEvent;
      form.setFieldValue(props.field.name, file)
    }
  }

  return (
    <div style={{position: "relative"}}>
      <input
        type="file"
        name="file"
        onChange={(event) => {
          fetchValue( event);
        }}/>
    </div>
  )
}
