import React, {useEffect, useRef, useState} from "react";
import axios from "axios"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";

export function VideoSelectorFile({ value, form, content, contents, deletable , i, ...props }) {
  const [imgBase64, setImgBase64] = useState(""); 
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const [isLoading, setLoading] = useState(false);

  function fetchValue(e) {
    setLoading(true)
    e.preventDefault();
    e.stopPropagation();
    
    const {target: {files}} = e;
    const file = files[0];
    console.log(file)
    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = (finishedEvent) => {
      const {currentTarget : { result }} = finishedEvent;
      setImgBase64(result);
      form.setFieldValue(props.field.name, file)
    }
    setLoading(false);
  }
  function fileToString(file){
    if(window.FileReader) {
      let reader = new FileReader();
      if (file && file.type.match('video.*')) {
        reader.readAsDataURL(file)
      } 
      reader.onloadend = (finishedEvent) => {
        const {currentTarget : { result }} = finishedEvent;
        setImgBase64(result);
      }
    }
  }

  useEffect(()=>{
    if(typeof value !== "string"){
      fileToString(value)
    }
  }, [value]);

  function deleteImg(){
    if(content.contentKey){
      axios.post("superstudy/setPostImageDel", content)
      .then(()=> {
        form.setFieldValue("contents", contents.filter((c)=>(c.contentKey !== content.contentKey)))
      })
    }
    else{
      form.setFieldValue("contents", contents.filter((c, index)=>(index !== i)))
    }
  }
  return (
    <div style={{position: "relative"}}>
        {isLoading && <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>}
      <input
        type="file"
        name="file"
        ref={hiddenFileInput}
        onChange={(event) => {
          fetchValue( event);
        }}
        style={{display: 'none'}}
      />
        {deletable && <button style={{position: "absolute", zIndex: 2, padding: "3px"}}
        type="button"
        onClick={deleteImg}
        className="btn btn-light"
      >
        <i className="fas fa-minus"></i>
      </button>}
      <div className="symbol symbol-150 symbol-xxl-100" onClick={handleClick} style={{backgroundColor: "black"}}>
        {(!imgBase64) && <>{value ? <div>
          <span className="svg-icon text-muted svg-icon-default svg-icon-xxl"
            style={{ position: "absolute", top: "60px", left: "60px" }}>
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Files/Media.svg"
              )}
            ></SVG>
          </span>
          <img className="symbol-label" src={value} alt="비디오"></img>
        </div>
          :
          <div className="symbol-label">그룹이미지</div>}</>}
        {(imgBase64) && <video width="150px" height="150px" controls><source src={imgBase64} alt="비디오" /></video>}
      </div>
    </div>
  )
}
