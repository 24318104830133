import React from "react";
import { Field } from "formik";
import axios from "axios"
import { Input } from "_metronic/_partials/controls";

export function UrlContentForm({ value, setFieldValue, content, contents, name, i ,...props }) {

  function deleteImg(){
    if(content.contentKey){
      axios.post("superstudy/setPostImageDel", content)
      .then(()=> {
        setFieldValue("contents", contents.filter((c)=>(c.contentKey !== content.contentKey)))
      })
    }
    else{
      setFieldValue("contents", contents.filter((c, index)=>(index !== i)))
    }
  }

  return (
    <div style={{ marginBottom: "10px", width: "100%" }} key={content.contentKey} >
      <div style={{display: "inline-block", width: "95%"}} className="mr-2">
        <Field
          name={name}
          component={Input}
          label="공유 URL 링크"
        />
      </div>
      <button style={{ padding: "3px" }}
        type="button"
        onClick={deleteImg}
        className="btn btn-light"
      >
        <i className="fas fa-minus"></i>
      </button></div>
  )
}