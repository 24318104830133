const dev = {
  siteName: '[TEST] 슈퍼스터디 관리자페이지',
  url: {
    apiUrl: 'http://15.165.136.225:8082',
    FileUrl: "https://t-file.modoogong.com",
    s3BuketUrl: 'https://studyapp-superstudy.s3.ap-northeast-2.amazonaws.com'
  },
};

const prod = {
  siteName: '슈퍼스터디 관리자페이지',
  url: {
    apiUrl: 'https://superstudy-api.bravecompany.io',
    FileUrl: "https://file.modoogong.com",
    s3BuketUrl: 'https://d8zggfbnffczw.cloudfront.net'
  },
};

const config = process.env.REACT_APP_ENV === 'release' ? prod : dev;

export const API = config.url.apiUrl;
export const S3_URL = config.url.s3BuketUrl;
export const SITE_NAME = config.siteName;

console.log(API);
console.log(
  "================ NODE_ENV : " + process.env.NODE_ENV + "============"
);
