import React, { useState, createContext, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";

function SimpleModal({ show, onHide, title, message  }) {


  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {message}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            닫기
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
};

const SimpleModalContext = createContext({});

const SimpleModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});

  const openModal = ({ title, message }) => {
    setModalOpen(true);
    setModalConfig({ title, message });
  };

  const resetModal = () => {
    setModalOpen(false);
    setModalConfig({});
  };

  const onHide = () => {
    resetModal();
  };

  return (
    <SimpleModalContext.Provider value={{ openModal, onHide}}>
      <SimpleModal
        show={modalOpen}
        title={modalConfig?.title}
        message={modalConfig?.message}
        onHide={onHide}
      />
      {children}
    </SimpleModalContext.Provider>
  )
};

const useSimpleModal = () => {
  const {openModal, onHide} = useContext(SimpleModalContext);

  const getModal = ({...options}) => {
    openModal(options);
    setTimeout(()=>{
      onHide();
    }, 2500)
  };

  return {getModal};
};

export {SimpleModalProvider, useSimpleModal}