import React from "react";

export function Radio({
  label,
  type = "radio",
  field,
  form: { touched, errors },
  ...props
}) {

  return (
    <>
      {label && (
        <label className="mr-3">
          <input className="mr-1" type={type} checked={String(field.value) === props.value} {...field} {...props} />
          {label}
        </label>
      )}
    </>
  );
}
