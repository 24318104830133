import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
  id: '',
  domain: "gong"
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [tab, setTab] =useState("gong");

  const LoginSchema = Yup.object().shape({
    id: Yup.string()
      .min(3, "아이디는 최소 3글자 입니다.")
      .max(50, "아이디는 최대 50글자 입니다.")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "비밀번호는 최소 3글자 입니다.")
      .max(50, "아이디는 최대 50글자 입니다.")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      console.log(values)
      setTimeout(() => {
        login(values.id, values.password, tab)
          .then(res => {
            if (res.data.resultCode === 1) {
              disableLoading();
              props.login({...res.data.admin, domain: tab});
            } else {
              disableLoading();
              setSubmitting(false);
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            }
          })
          .catch(() => {
            console.log('err')
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
        // .then(({ data: { accessToken } }) => {
        //   disableLoading();
        //   props.login(accessToken);
        // })
        // .catch(() => {
        //   console.log('err')
        //   disableLoading();
        //   setSubmitting(false);
        //   setStatus(
        //     intl.formatMessage({
        //       id: "AUTH.VALIDATION.INVALID_LOGIN",
        //     })
        //   );
        // });
      }, 1000);
    },
  });
  
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
          <img src="/media/logos/img_login_title.png" alt="슈퍼수강앱 어드민" style={{width: "200px"}}/>
      
        <p className="text-muted font-weight-bold mt-8">
          아이디와 비밀번호를 입력하세요.
        </p>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("gong")}>
            <p
              className={`nav-link ${tab === "gong" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "gong").toString()}
            >
              <img src="/media/logos/logo_gong.png" alt="슈퍼수강앱 어드민" style={{width: "70px", padding: "10px"}}/>
          </p>
          </li>
          <li className="nav-item" onClick={() => setTab("cop")}>
            <p
              className={`nav-link ${tab === "cop" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "cop").toString()}
            >
              <img src="/media/logos/logo_cop.png" alt="슈퍼수강앱 어드민" style={{width: "70px", padding: "10px"}}/>
          </p>
          </li>
          <li className="nav-item" onClick={() => setTab("fire")}>
            <p
              className={`nav-link ${tab === "fire" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "fire").toString()}
            >
              <img src="/media/logos/logo_fire.png" alt="슈퍼수강앱 어드민" style={{width: "70px", padding: "10px"}}/>
          </p>
          </li>
          <li className="nav-item" onClick={() => setTab("gun")}>
            <p
              className={`nav-link ${tab === "gun" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "gun").toString()}
            >
              <img src="/media/logos/logo_gun.png" alt="슈퍼수강앱 어드민" style={{ width: "70px", padding: "10px" }} />
          </p>
          </li>
        </ul>

      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
            <div>
            </div>
          )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="아이디"
            type="id"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "id"
            )}`}
            name="id"
            {...formik.getFieldProps("id")}
          />
          {formik.touched.id && formik.errors.id ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.id}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="비밀번호" 
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div style={{flexDirection: "row-reverse"}} className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>로그인</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
