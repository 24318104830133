import React from "react"
import {toAbsoluteUrl} from "_metronic/_helpers";
export function ErrorsPage(){
  return (
    <div className="d-flex flex-column flex-root">
    <div
      className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/error/bg6.jpg")})`
      }}
    >
      <h1
        className="font-size-sm-100 font-weight-boldest text-white mt-15"
        style={{ fontSize: "150px" }}
      >
        404
      </h1>
      <p className="font-size-h3 font-weight-light text-white">
       페이지를 찾을수 없습니다.
      </p>
    </div>
  </div>
  )
}