import React from "react";
import {Chip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
  },
}));

export function PostTagOnOffButton({tag, setFieldValue, fieldValues, name, handleSubmit, setTagEmpty}){
  const classes = useStyles();
  let tags = []
  return (
      <>
    {(fieldValues || fieldValues === 0) && <Chip
    label={tag.tagName}
    key={tag.tagType}
    onClick={() => {
      if(typeof fieldValues === "number"){
        setFieldValue(name, tag.tagType == fieldValues ? tag.defaltType : tag.tagType)
        handleSubmit && handleSubmit()
      }else{
        tags = fieldValues.filter(t=>t !== 0);
        (setFieldValue && tags) &&  setFieldValue(name, tags.indexOf(tag.tagType) !== -1 ? tags.filter(v => v !== tag.tagType) : [...tags, tag.tagType])
        setTagEmpty && setTagEmpty()
      }
  }}
    className={classes.chip}
    color={typeof fieldValues === "number" ? (tag.tagType == fieldValues ? "secondary" : "default") : fieldValues.indexOf(tag.tagType) !== -1 ? "secondary" : "default"}
    variant="outlined"
  />}
  </>
  )
}