import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import Icon from '@material-ui/core/Icon';

// const getFieldCSSClasses = (touched, errors) => {
//   const classes = ["form-control"];
//   if (touched && errors) {
//     classes.push("is-invalid");
//   }

//   if (touched && !errors) {
//     classes.push("is-valid");
//   }

//   return classes.join(" ");
// };
// function fomatter(val){
//   let year = val.getFullYear()
//   let month = val.getMonth() + 1
//   let day = val.getDate() < 10 ? `0${val.getDate()}`: val.getDate()
//   return `${year}-${month < 10 ? "0" + month : month}-${day}`
// }
export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <input
        type="date"
        className="form-control"
        name="joinStartDate"
        value={field.value}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value);
        }}
      />
      {/* <span className="btn btn-icon btn-light btn-sm">
        <Icon>calendar_today</Icon>
      </span>
      <DatePicker
        locale={ko}
        dateFormat="yyyy-MM-dd"
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          console.log(fomatter(val))
          setFieldValue(field.name, fomatter(val));
        }}
      /> */}
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
        
        </div>
      )}
    </>
  );
}
