import * as fetchAdmin from "app/controllers/admin";
import {getProfile} from "app/controllers/user";

export function login(id, password, domain) {
  return fetchAdmin.adminLogin({ id: id, pwd: password, domain: domain })
}

// export function register(email, fullname, username, password) {
//   return axios.post(BASE + REGISTER_URL, { email, fullname, username, password });
// }

// export function requestPassword(email) {
//   return axios.post(BASE + REQUEST_PASSWORD_URL, { email });
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  var token = window.localStorage.getItem("token")
  return getProfile(token)
}

export function getUserById(id, domain){
  return getProfile(id, domain)
}