import React, {useEffect, useRef, useState} from "react";
import axios from "axios"

export function ImageSelectorFile({ value, form, content, contents, deletable, i ,...props }) {
  const [imgBase64, setImgBase64] = useState(""); 
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  function fetchValue(e) {
    const {target: {files}} = e;
    const file = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = (finishedEvent) => {
      const {currentTarget : { result }} = finishedEvent;
      setImgBase64(result);
      form.setFieldValue(props.field.name, file)
    }
  }
  function fileToString(file){
    if(window.FileReader) {
      let reader = new FileReader();
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      } 
      reader.onloadend = (finishedEvent) => {
        const {currentTarget : { result }} = finishedEvent;
        setImgBase64(result);
      }
    }
  }

  useEffect(()=>{
    if(typeof value !== "string"){
      fileToString(value)
    }
  }, [value]);

  function deleteImg(){
    if(content.contentKey){
      axios.post("superstudy/setPostImageDel", content)
      .then(()=> {
        form.setFieldValue("contents", contents.filter((c)=>(c.contentKey !== content.contentKey)))
      })
    }
    else{
      form.setFieldValue("contents", contents.filter((c, index)=>(index !== i)))
    }
  }
  return (
    <div style={{position: "relative"}}>
      <input
        type="file"
        name="file"
        ref={hiddenFileInput}
        onChange={(event) => {
          fetchValue( event);
        }}
        style={{display: 'none'}}
      />
        {deletable && <button style={{position: "absolute", zIndex: 2, padding: "3px"}}
        type="button"
        onClick={deleteImg}
        className="btn btn-light"
      >
        <i className="fas fa-minus"></i>
      </button>}
      <div className="symbol symbol-150 symbol-xxl-100" onClick={handleClick}>
       {(!imgBase64) && <>{value ? value === "https://studyapp-superstudy.s3.ap-northeast-2.amazonaws.com"  ? <div className="symbol-label">그룹이미지</div> : <img className="symbol-label" src={value} alt="그룹이미지"></img> : <div className="symbol-label">그룹이미지</div> }</>}
        {(imgBase64) && <img className="symbol-label" src={imgBase64} alt="그룹이미지"></img>}
      </div>
    </div>
  )
}
