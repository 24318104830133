import React, {useState} from "react";
import {Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
  },
}));
export function Tag({tag, form, ...props}) {
  const classes = useStyles();
  return (
    <Chip
      label={tag.tagName}
      key={tag.tagType}
      onDelete={(e) => form.setFieldValue(props.field.name, form.values.postTags.filter(t => t.tagType !== tag.tagType))}
      className={classes.chip}
      variant="outlined"
      color={form.values.inputTagType === tag.tagType ? "primary" : "default"}
      onClick={()=>{
          if(form.values.inputTagType === tag.tagType){
            form.setFieldValue("inputTagType", "default");
            form.setFieldValue("inputTagName", "");
          }else{
            form.setFieldValue("inputTagType", tag.tagType);
            form.setFieldValue("inputTagName", tag.tagName);
          }
      }}
    />

  )
}