/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import {SimpleModalProvider} from "app/components/modal/SimpleModal";
import {ConfirmModalProvider} from "app/components/modal/ConfirmModal";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools';
import {SITE_NAME} from "./config"

const queryClient = new QueryClient();
export default function App({ store, persistor }) {
  useEffect(()=>{
    document.title = SITE_NAME;
  },[])
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          {/* <React.Suspense fallback={<LayoutSplashScreen />}> */}
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <SimpleModalProvider>
                  <ConfirmModalProvider>
                    <Routes />
                  </ConfirmModalProvider>
                </SimpleModalProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
          {/* </React.Suspense> */}
        </PersistGate>
      </Provider>
      <ReactQueryDevtools/>
    </QueryClientProvider>
    /* Provide Redux store */

  );
}
