import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
const SuperStudyBasePage = lazy(() =>
  import("./modules/SuperStudy/SuperStudyBasePage")
);
const StatisBasePage = lazy(() =>
  import("./modules/Statis/StatisBasePage")
);
const TalkBasePage = lazy(()=>
  import("./modules/Talk/TalkBasePage")  
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/superstudy" />
        }
        <Route path="/superstudy" component={SuperStudyBasePage} />
        <Route path="/statis" component={StatisBasePage} />
        <Route path="/talk" component={TalkBasePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
