import React from "react";
import Select from 'react-select';

export function MultipleSelect({
  label, 
  name,
  options,
  ...props
}){
  const onChange = (value) =>{
    props.onChange(name, value)
  };
  return (
    <div>
      <label>{label}</label>
      <Select
      isMulti={true}
      options={options}
      onChange={onChange}
      onBlur={()=>props.onBlur(name, true)}
      value={props.value}
      />
    </div>
  )
}