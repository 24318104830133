import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Topbar} from "./Topbar";
import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
import {AnimateLoading} from "../../../_partials/controls";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { makeStyles } from '@material-ui/core/styles';
import {SubHeader} from "../subheader/SubHeader";

const useStyles = makeStyles(theme => ({
  right: {
    justifyContent: "flex-end !important"
  }
}));
export function Header() {
  const uiService = useHtmlClassService();
  
  const classes = useStyles();
  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <>
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
        // style={{justifyContent: "flex-end !important"}}
      >
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch${classes.right}`}
        style={{display: "flex", flexDirection: "row-reverse"}}>
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {/* {/* {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />} */}
          {!layoutProps.menuHeaderDisplay && <div />} 
          {/* <SubHeader/> */}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar className="float-right"/>
    
          {/*end::Topbar*/}
        </div>
      </div>
    </>
  );
}
