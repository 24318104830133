export default function setupAxios(axios, store) {
  let domain = window.sessionStorage.getItem("domain");
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      //config 설정 영역
      const addTokenAndDomain = () => {
        config.headers.Authorization = `Bearer ${authToken}`;
        if (config.headers["Content-Type"] === "multipart/form-data") {
          // formdata 타입일 때
          // const dataWithDonmain = config.data.append("domain", authToken.domain);
          // config.data = dataWithDonmain;
        }else{
          // 일반 요청일 때
          config.data = { ...config.data, domain: authToken.domain };
        }
      }
      if (authToken) {
        addTokenAndDomain();
      } else {
        // 로그인 제외 최초 요청시 잠깐 기다렸다가 재실행
        if(!config.data.domain){
          setTimeout(()=>{
            if(domain){
              config.data = { ...config.data, domain: JSON.parse(domain) };
            }
          }, 500);
        };
      };
      return config;
    },
    err => Promise.reject(err)
  );
}
