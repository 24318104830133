import React, {useRef} from "react";

export function ImageSelectorAddButtonFile({initContent, form, values ,...props }){
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  function fetchValue(e) {
    const {target: {files}} = e;
    const file = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = (finishedEvent) => {
      const {currentTarget : { result }} = finishedEvent;
      const content = {...initContent, contentBody: file}
      form.setFieldValue(props.field.name, [...values, content])
    }
  }
  return (
    <div>
      <input
        type="file"
        name="file"
        ref={hiddenFileInput}
        onChange={(event) => {
          fetchValue(event);
        }}
        style={{ display: 'none' }}
      />
      <button
        type="button"
        onClick={handleClick}
        className="btn btn-light"
      >
        <i className="fas fa-image"></i>
        <i className="fas fa-plus"></i>
      </button>
    </div>

  )
}