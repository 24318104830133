import React, {useMemo} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {CoachAsideMenuList} from "./CoachAsideMenuList"
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {useSelector} from "react-redux";

export function AsideMenu({disableScroll}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  const user = useSelector(state=>state.auth.user);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {user.type === 1 ? <AsideMenuList layoutProps={layoutProps}/> : <CoachAsideMenuList layoutProps={layoutProps}/>}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
