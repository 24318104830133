import React from "react";

export function TimePicker({value, form, ...props}){
  return (
    <input 
    className="form-control"
    label={props.label}
    type="time"
    step="300"
    value={props.field.value}
    onChange={(e)=>form.setFieldValue(props.field.name, e.target.value)}/>
  )
}