import React, { useState, createContext, useContext } from "react";
import { Modal } from "react-bootstrap";

function ConfirmModal({ show, onHide, title, message, onConfirm }) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {message}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            닫기
          </button>
          <button
            type="button"
            onClick={onConfirm}
            className="btn btn-primary btn-elevate"
          >
            확인
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
};

const ConfirmModalContext = createContext({});

const ConfirmModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});

  const openModal = ({ title, message, actionCallback }) => {
    setModalOpen(true);
    setModalConfig({ title, message, actionCallback });
  };

  const resetModal = () => {
    setModalOpen(false);
    setModalConfig({});
  };

  const onConfirm = () => {
    resetModal();
    modalConfig.actionCallback(true);
  };


  const onHide = () => {
    resetModal();
    modalConfig.actionCallback(false);
  };

  return (
    <ConfirmModalContext.Provider value={{ openModal }}>
      <ConfirmModal
        show={modalOpen}
        title={modalConfig?.title}
        message={modalConfig?.message}
        onHide={onHide}
        onConfirm={onConfirm}
      />
      {children}
    </ConfirmModalContext.Provider>
  )
};

const useConfirmModal = () => {
  const {openModal} = useContext(ConfirmModalContext);

  const getConfirmation = ({...options}) => (
    new Promise((res) => {
        openModal({actionCallback: res, ...options});
    })
  );

  return {getConfirmation};
};

export {ConfirmModalProvider, useConfirmModal}