import React from "react";
import { Field } from "formik";
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textarea: {
    height: "200px !important",
  },
}));
export function Textarea({ name, label, placeholder}) {
  const classes = useStyles();
  return (
    <>
      <Field
        name={name}
        as="textarea"
        className={`form-control ${classes.textarea}`}
        placeholder={placeholder}
        label={label}
      />
    </>
  )
}