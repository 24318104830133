import axios from "axios";

export function adminLogin(user){
  return axios.post( "/webAdmin/login", user, {
    timeout: 2000
  })
  .then(res => {
    window.sessionStorage.setItem("auth", JSON.stringify({isAuthorized: true}));
    window.sessionStorage.setItem("domain", JSON.stringify(user.domain));
    return res;
  });
}